import React from 'react';
import { NavLink } from 'reactstrap';

class LandingPage extends React.Component {

    render() {
        return (
            <div className="fullpage bg-image landing-page">
                <div className="container h-100" id="about">
                    <div className="row h-100">
                        <div className="col-12 my-auto text-center text-white">
                            <h1>WonderMine</h1>
                            <h3>Mining and crafting in the metaverse!</h3>
                            <img className="img-fluid mb-6" src="img/wondermine_meteor3.jpg" alt="" width="1136"/>
                        </div>
                    </div>
                    <div className="scroll-down">
                        <a className="btn js-scroll-trigger" href="#instructions">
                            <i className="fa fa-angle-down fa-fw"></i>
                        </a>
                    </div>
            
                <div className="page-section bg-dark my-5" id="faq">
                    <div className="container wow fadeIn">
                        <div className="row my-5">
                            <div className="col-lg-12 text-left my-auto">
                                <h2>Frequently Asked Questions</h2>
                                <hr className="colored" />
                                
                                <h3>The Game</h3>
                                <h4>Where is it?</h4>
                                <p>WonderMine lives in <a href="https://www.decentraland.org" target="_blank" rel="noopener noreferrer">Decentraland</a>, a free-to-play virtual world. 
                                We're in the WonderZone Amusement Park district at <a href="https://play.decentraland.org/?position=-29%2C56" rel="noopener noreferrer" target="_blank">-29,56.</a></p>
                                <h4>What is WonderMine about?</h4>
                                <p>The basic game is simple:</p>
                                <ul>
                                    <li>Mine meteors to collect loot</li>
                                    <li>Craft materials and better equipment at the crafting machine</li>
                                    <li>Collect enough and you can craft Decentraland Wearables and virtual goods that have real value!</li>
                                </ul>
                                <img className="img-fluid mb-5 float-right" src="img/wondermine_meteor1.jpg" alt="" width="638" style={{paddingLeft:"10px"}}/>
                                <p>What makes WonderMine even more fun are the other players nearby. Start a conversation, show off your latest gear, have a dance-off. 
                                    It's a lot more engaging when you're mining with friends!
                                </p>
                                
                                <h4>How do I play?</h4>
                                <p>It's easy to start playing:</p>
                                <img className="img-fluid mb-5 float-right" src="img/wondermine_inventory.jpg" alt="" width="450" style={{paddingLeft:"10px"}}/>
                                <ol>
                                    <li>
                                        Visit <a href="https://www.decentraland.org" target="_blank" rel="noopener noreferrer">Decentraland</a>.</li>
                                    <li>
                                        Go to WonderMine at <a href="https://play.decentraland.org/?position=-29%2C56" target="_blank" rel="noopener noreferrer">-29,56.</a>.</li>
                                    <li>
                                        Be careful! Meteors are falling!</li>
                                    <li>
                                        Run to the first meteor you see and click it. Your pickaxe starts mining the meteor.</li>
                                    <li>
                                        When the meteor breaks, you get loot! See all the loot you've collected in your inventory.</li>
                                    <li>
                                        Keep mining to collect more loot. Soon you'll have enough to do some crafting.</li>
                                </ol>
                                
                                <h4>What's all that stuff?</h4>
                                <p>Your Inventory Bag holds all the materials you've collected, and the items you've crafted.</p>
                                <p>Meteors produce four kinds of gems: Sapphire, Emerald, Ruby and Diamond. And five kinds of metals: Copper, Iron, Titanium, Gold and Platinum.</p>
                                <p>You also see the interim materials you'll need to make wearables: Blue Fabric, Glowmetal, and Wearables Tokens. These can all be crafted from basic materials in the Crafting Machine.</p>
                                <p>Occasionally, during special in-game events, a Gift Box might also emerge from a meteor. You can usually exchange that gift box for a valuable event prize. </p>
                                
                                <img className="img-fluid mb-5 float-right" src="img/wondermine_mined_popup.jpg" alt="" width="638" style={{paddingLeft:"10px"}}/>
                                <h4>What does the Mining Bonus do?</h4>
                                <p>Your Mining Bonus increases based on your level in the game, the type of pickaxe you use, and which wearables your avatar is wearing when you play.</p>
                                <p>The greater your Mining Bonus, the higher chance you have at getting Bonus Loot Drop when mining. A Bonus Loot Drop gives an extra material, pulled from a higher-value pool. You're more likely to get rare gems and metals, like Diamonds and Platinum, from that bonus drop.</p>
                                <p>You can also see your current Mining Bonus in the inventory display.</p>

                                <h4>How do I craft things?</h4>
                                <p>The Crafting Machine transforms specific sets of materials into other items in the most amazing way.</p>
                                <p>Use the red arrow buttons to browse through the crafting recipes. 
                                    If you already have enough of an ingredient, it has a green background on the screen.</p>
                                <p>If you have all of the ingredients needed, the lever to the right of the screen starts glowing green. 
                                    To craft the recipe item, click the glowing lever, and then watch the machine go to work!</p>

                                <img className="img-fluid mb-5 float-left" src="img/wearables_collage.png" alt="" width="272"/>
                                <h4>Can I craft real Decentraland Wearables?</h4>
                                <p>Yes! Currently you can craft any item from our Meteor Chaser and Steampunk collections!</p>
                                <p>After you craft them, your avatar can wear then anywhere in Decentraland. Or you can sell or trade them 
                                    in the <a href="https://market.decentraland.org" target="_blank" rel="noopener noreferrer">Decentraland Marketplace</a> or on <a href="https://www.opensea.io" rel="noopener noreferrer" target="_blank">OpenSea</a></p>
                                <p> </p>
                                
                                <h4>What's with all the pickaxes?</h4>
                                <p>Every miner needs the right equipment. You get a Stone Axe when you first arrive. It whacks those meteors pretty well, but it doesn't last very long between repairs.</p>
                                <p>After you collect some materials, craft a new, more efficient Copper Axe. It last longer and gives you a 10% Mining Bonus -- a chance at an extra high-value material from each meteor.</p>
                                <p>Keep crafting better axes until you create the Diamond Pickaxe, the ultimate meteor hacker! The Diamond Pickaxe gives a 50% Mining Bonus, and lasts 500 uses between repairs.</p>
                                <img className="img-fluid mb-5 float-right" src="img/wondermine_axes.jpg" alt="" width="638" style={{paddingLeft:"10px"}}/>
                                <h4>Is the Diamond Pickaxe really an NFT?</h4>
                                <p>Yes. It's the first in a line of WonderZone NFTs.</p>
                                <p>Or rather, it will be soon. We'll deliver the NFT version of the Diamond Pickaxe and other WonderZone NFTs in early 2021.</p>

                                <h4>Do I have to pay to play?</h4>
                                <p>Nope. Zero. Zip. Nada.</p>
                                <p>WonderMine is a rare free-to-play cryptogame (just like Decentraland itself).</p>
                                <p>Every player gets 100 WonderCoins a day for free. If you want more coins you can purchase some at the Coin Cart for MANA, the Decentraland currency. 
                                    But that is completely optional.</p>
                                
                            </div>
                        </div>
                    </div>

                </div>

                </div>
            <div className="footer" id="footer">
              <div className="container text-center">
                  <div className="row footer-social">
                      <div className="col-lg-12">
                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <a href="https://twitter.com/WonderZoneGames" target="_blank" rel="noopener noreferrer">
                              <i className="fab fa-twitter fa-fw fa-2x"></i>
                            </a>
                          </li>
                          <li className="list-inline-item">
                              <a href="https://discord.gg/pRczkzh" target="_blank" rel="noopener noreferrer">
                                <img src="img/discord-logo-white-128x128.fw.png" alt="" width="32"/>
                              </a>
                            </li>
                        </ul>
                      </div>
                    </div>
                {/* <div className="row">
                  <div className="col-lg-4 footer-contact-details mx-auto">
                      <div className="list-group">
                          <a href="https://www.wonderzone.io/privacy.html" className="list-group-item list-group-item-action ">
                            Privacy Policy
                          </a>
                          <a href="https://www.wonderzone.io/terms.html" className="list-group-item list-group-item-action">Terms of Use</a>
                        </div>
                  </div>
                </div> */}
                <NavLink href="/terms">Terms of Use</NavLink>
                <p className="copyright">&copy; 2020 Decent Amusements LLC. All rights reserved.</p>
              </div>
            </div>

            </div>

        );
    }
}

export default LandingPage;
