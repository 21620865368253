// local
// export const config = {
//     "wearClaimerContract" : "0x276b661495720ae4EE09118C48D1D4871116083f",
//     "fallbackUrl" : "https://ropsten.infura.io/v3/32fe444efa7d46c39aa82bb78ad14624",
//     "fallbackType" : "http",
//     "infura" : "wss://ropsten.infura.io/ws/v3/32fe444efa7d46c39aa82bb78ad14624",
//     "wzApi" : "http://wondermine-test1-api.azure-api.net/wondermine-api1/",
//     "origin": "http://localhost:3000"
// };

// Production
export const config = {
    "wearClaimerContract" : "0x276b661495720ae4EE09118C48D1D4871116083f",
    "wearClaimerV2Contract" : "0x12f2CD57353Ac7A2BE8388976a06c51a5D130804",
    "fallbackUrl" : "https://mainnet.infura.io/v3/32fe444efa7d46c39aa82bb78ad14624",
    "fallbackType" : "http",
    "infura" : "wss://mainnet.infura.io/ws/v3/32fe444efa7d46c39aa82bb78ad14624",
    "infuraPolygon": "https://polygon-mainnet.infura.io/v3/32fe444efa7d46c39aa82bb78ad14624",
    "wzApi" : "https://apim-dev-west-wondermine-api.azure-api.net/",
    //"wzApi" : "https://func-dev-west-wondermine-app.azurewebsites.net/api/",
    "openCode": "0tZE7VsPxZ58dygmeMXWzEhFRHP5P116fEVAjHgj2joJAzFuAjwN7g==",
    //"wzApi" : "https://wondermine-test1-api.azure-api.net/wondermine-api1/",
    "origin": "*",
};

// Wearables Contracts (on Matic Mainnet)
/*
   "wzLogoApparel": "0xd89efd0be036410d4ff194cd6ecece4ef8851d86",
   "bigNose": "0x0c861e408400c031f20ece5aa87aed5b73a351df",
   "tigerWarrior": "0xa4b33f44addd9732b7e9c33c7621f2f1d983a78a",
   "cybertorian": "0x28280eee597eb8ae4d8baa56332e0322acb8f7bc",
   "hornsOfWonder": "0x388323ad9a9da54a8581214a471040c66c6b7e5c",
   "knittedWinter": "0x651fa103e616bfca36523ab2c1895c40d1853bd7",
   "wzUkraineCharity": "0x1e36e2b1153511eaf9d93ed20001bf1a4a97c0a1",
   "wzSneakersSpring2022": "0xbc9c07ca0a8183cc318b1f10974889e05daa7a3c",
   "Wonderpunk": "0x324cb2c654be51c6ad6c76a3e022cabe49cc4e46",
   "MCMC": "0x67"
*/