import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import '../css/react-table.css';

class ClaimList extends React.Component {

    constructor(props) {
        super(props);
        //console.log("props = " + JSON.stringify(this.props));;
        //console.log("ClaimList data length = " + this.props.claimData.length);
        this.state = {
            //data: this.props.claimData
        };
        this.showClaim = this.showClaim.bind(this);
    }

    showClaim(claimInfo)
    {
        //console.log("claim=" + JSON.stringify(claimInfo));
        this.props.selectClaim(claimInfo); // function in the props!
    }

    async componentDidMount() {}

    render() {

        // const dateOptions = {
        //     year: 'numeric',
        //     month: 'short',
        //     day: 'numeric',
        //     hour: '2-digit',
        //     minute: '2-digit'
        // };

        const columns = [
            {
                Header: '',
                accessor: 'iconUrl',
                filterable: false,
                maxWidth: 72,
                Cell: row => (
                      <img src={'/img/icons/' + row.value}
                        width='64' 
                        height='64'
                        alt='wearable icon'
                        style={{
                          borderRadius: '2px',
                          margin: '-20px 2px 2px 2px',
                          transition: 'all .2s ease-out'
                        }}
                      />
                  )
            }, {
                Header: 'Item',
                accessor: 'itemName',
                maxWidth: 190,
                filterMethod: (filter, row) =>
                    String(row[filter.id]).toLowerCase().includes(String(filter.value).toLowerCase())
            }, {
                Header: 'Date',
                accessor: 'time',
                maxWidth: 160
            }, {
                Header: 'Claim #',
                accessor: 'claimNum',
                maxWidth: 80,
                Cell: props => <span className='number'>{props.value}</span> 
            }, {
                Header: 'Status',
                accessor: 'status',
                maxWidth: 150,
                Cell: props => (
                    <button className={(props.value === "claimed" || 
                                        props.value === "eth main" || 
                                        props.value === "matic" || 
                                        props.value === "waiting..." ||
                                        props.value === "change network") ? "btn-disabled" : "btn-primary" } 
                            disabled={(props.value === "claimed" || 
                                        props.value === "eth main" || 
                                        props.value === "matic" || 
                                        props.value === "waiting..." ||
                                        props.value === "change network")} 
                            style={{padding: "6px 10px", marginTop: "-12px"}} 
                            value={props.value} 
                            onClick={ e => { this.showClaim(props.original); } }>
                        {props.value}
                    </button>
                )
                // Cell: props => (
                //     <button className="btn-primary" disabled={true} style={{padding: "6px 10px", marginTop: "-12px"}} value={props.value} onClick={ (e) => console.log("CLICK: " + props.value) }>
                //       {props.value}
                //     </button>
                // )
            }
            // }, {
            //     Header: 'Wearable Id',
            //     accessor: 'wi',
            //     filterable: false,
            //     maxWidth: 90
            // }, {
            //     Header: 'Signature',
            //     accessor: 'sig',
            //     maxWidth: 200
        ];

        return (
            <div>
                <ReactTable
                    data={this.props.claimData}
                    pageSize={10}
                    // filterable
                    // defaultFilterMethod={(filter, row) =>
                    //   String(row[filter.id]) === filter.value}
                    columns={columns}
                    getTdProps={(state, rowInfo, column, instance) => {
                        return {
                            // onClick: e => {
                            //     console.log("Cell - onClick", {
                            //         state,
                            //         rowInfo,
                            //         column,
                            //         instance,
                            //         event: e
                            //     }); 
                            //     if (rowInfo != undefined)
                            //     {
                            //         this.showClaim(rowInfo.original);
                            //     }
                            // }
                        };
                    }}
                    defaultSorted={[
                        {
                          id: "claimNum",
                          desc: true
                        }
                      ]}
                    className="-striped -highlight"/>
            </div>
        );
    }
}

ClaimList.propTypes = {
    claimData: PropTypes.array.isRequired,
    selectClaim: PropTypes.func.isRequired
};

export default ClaimList;
