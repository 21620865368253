import React, { Component } from 'react';

import { Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Nav, Collapse } from 'reactstrap';

class Header extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  render() {
    
    return (

    <div className="fixed-top" id="mainNav">

      <Navbar color="dark" dark expand="md">
        <NavbarBrand href="https://wondermine.wonderzone.io">WonderZone</NavbarBrand>
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLink href="https://wondermine.wonderzone.io">Home</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/">FAQ</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/claimitem">Mint Wearables</NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </div>

    );
  }
}

export default Header;
