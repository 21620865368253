import React from 'react'; // , { useState } 
import { 
    Alert,
    Row, 
    Col, 
    NavLink,
    // ListGroup, 
    // ListGroupItem, 
    // Button, 
    // Label,
    // Input,
    // Form,
    // FormGroup,
    // UncontrolledDropdown, 
    // Dropdown,
    // DropdownItem,
    // DropdownMenu,
    // DropdownToggle,
    // Spinner,
    // Toast,
    // ToastBody,
    // ToastHeader
 } from 'reactstrap';
//import { Link, Route } from 'react-router-dom';
import 'react-table/react-table.css';

import { config } from '../config/config.js';
import ClaimList from '../components/ClaimList.js';

//import Web3 from 'web3'; // web3 from MetaMask or another provider
import NewWeb3 from '../module/NewWeb3Module.js';
//import { object } from 'prop-types';

// 2DO: add icons
// 2DO: make Claim Now a button
// 2DO: check contract to see if already claimed (add a bulk check?)
// 

// single item claim page, for testing
class ClaimItemPage extends React.Component {

    constructor(props) {
        super(props);

        this.wearableKeys = [
            "meteorchaser_transmitter_earring",
            "meteorite_dustmask_mask",
            "meteorchaser_shoes_feet",
            "meteorchaser_trousers_lower_body",
            "meteorite_protective_hardhat_hat",
            "meteorchaser_vest_upper_body",

            "steampunk_goggles",
            "steampunk_mask",
            "steampunk_boots",
            "steampunk_hat",
            "steampunk_trousers",
            "steampunk_jacket",
        ];

        this.wearableNames = {
            1000: "MC Ear Transmitters",
            1001: "MC Shoes",
            1002: "MC Hard Hat",
            1003: "MC Trousers",
            1004: "MC Dust Mask",
            1005: "MC Jacket",

            2000: "Steampunk Goggles",
            2001: "Steampunk Boots",
            2002: "Steampunk Hat",
            2003: "Steampunk Trousers",
            2004: "Steampunk Mask",
            2005: "Steampunk Jacket",

            3000: "WZ Logo Cap",
            3001: "WZ Logo T-shirt",

            4000: "Big Nose",

            5000: "Ram Horns",
            5001: "Demon Horns",
            5002: "Devil Horns",
            5003: "Antlers",
            5004: "Unicorn Horn",
            5005: "Wizard Horns",

            6000: "Tiger Torso",
            6001: "Tiger Headdress",
            6002: "Tiger Kilt",

            7000: "Dragon Head",
            7001: "Dragon Pants",
            7002: "Dragon Jacket",
            7003: "Dragon Boots",
            7004: "Dragon Helmet",

            8000: "Cybertorian Coat",

            9000: "Knit Hazel Beanie",
            9001: "Knit Lilac Beanie",
            9002: "Knit Hazel Sweater",
            9003: "Knit Lilac Sweater",

            11000: "Sneakers Spring 2022",

            12000: "SugarPunk Vest",
            12001: "CamoPunk Vest",
            12002: "CoalPunk Vest",
            12003: "Punk Joggers",
            12004: "Punk Hair Braids",
    
            13000: "MC Motorcycle Jacket",
            13001: "MC Motorcycle Goggles",
            13002: "MC Motorcycle Helmet",

            14000: "MC Red Motorcycle Jacket",
            14001: "MC Red Motorcycle Pants",
            14002: "MC Motorcycle Boots",
    
            15000: "Extravagant Regalia",
            15001: "Extravagant Vest",

            16000: "ICE Skeleton",

            17000: "Venetian Red Cap",
            17001: "Headless",
            17002: "Venetian Green Cap",
            17003: "Galaxy Sneakers",
            17004: "Murasaki Ronin Hat",
            17005: "Murasaki Ronin Torso",
            17006: "Murasaki Ronin Legs",
            17007: "Marcel Mask",
            17008: "Cowboy Hat",
            // 17009: "Wizard Hat",

            18000: "Nebula Sneakers",
            18001: "Cyber Druid Head",
            18002: "Cyber Druid Torso",
            18003: "Cyber Druid Legs",
            18004: "Mole Dive Emote",
            18005: "Wireframe Body",
            18006: "Bulging Eyes",
            18007: "Night Stalker Helmet",
            18008: "Mongolian Helmet",
            // 18009: "Wizard Beard"

            19000: "Kurai Ronin Hat",
            19001: "Kurai Ronin Legs",
            19002: "Kurai Ronin Torso",
            19003: "Cosmic Sneakers",
            19004: "Night Stalker Torso",
            // 19005: "Wizard Tunic",
            19006: "Viking Beard",
            19007: "Arctic Miner Hat",
            19008: "Arctic Miner Torso",
            19009: "Arctic Miner Legs",

            20000: "Terrified Emote", // R0096
            20001: "Buck Teeth", // R0097
            20002: "Night Stalker Guardian Legs", // R0098
            20003: "Norwegian Forest Troll", // R0099
            20004: "Asiatic Hairstyle", // R0100
            20005: "Mandarin Gown", // R0101
            20006: "Puppet Head", // R0102
            20007: "Puppet Torso", // R0103
            20008: "Puppet Legs", // R0104

            21000: "Yasei Ronin Hat",
            21001: "Yasei Ronin Torso",
            21002: "Yasei Ronin Legs",
            21003: "WonderChad",
            21004: "Monkey Head",
            21005: "Horse Head",
            21006: "Discord Mod Head",
            21007: "Discord Mod Torso",
            21008: "Discord Mod Legs",
            21009: "Wonder-Aura",
        }

        this.iconNames = {
            1000: "1000_ears.png",
            1001: "1001_shoes.png",
            1002: "1002_hardhat.png",
            1003: "1003_trousers.png",
            1004: "1004_dustmask.png",
            1005: "1005_jacket.png",

            2000: "2000_goggles.png",
            2001: "2001_boots.png",
            2002: "2002_hat.png",
            2003: "2003_trousers.png",
            2004: "2004_mask.png",
            2005: "2005_jacket.png",

            3000: "3000_wz_cap.png",
            3001: "3001_wz_shirt.png",

            4000: "4000_big_nose.png",

            5000: "5000_ram_horns.png",
            5001: "5001_demon_horns.png",
            5002: "5002_devil_horns.png",
            5003: "5003_antlers.png",
            5004: "5004_unicorn_horn.png",
            5005: "5005_wizard_horns.png",

            6000: "6000_tiger_torso.png",
            6001: "6001_tiger_headdress.png",
            6002: "6002_tiger_kilt.png",

            7000: "7000_dragon_head.png",
            7001: "7001_dragon_pants.png",
            7002: "7002_dragon_jacket.png",
            7003: "7003_dragon_boots.png",
            7004: "7004_dragon_helmet.png",

            8000: "8000_cybertorian_coat.png",

            9000: "9003_hazel_beanie.png",
            9001: "9001_lilac_beanie.png",
            9002: "9002_hazel_sweater.png",
            9003: "9000_lilac_sweater.png",

            11000: "11000_sneakers.png",

            12000: "12000_sugarpunk.png",
            12001: "12001_camopunk.png",
            12002: "12002_coalpunk.png",
            12003: "12003_joggers.png",
            12004: "12004_braids.png",
    
            13000: "13000_jacket.png",
            13001: "13001_goggles.png",
            13002: "13002_helmet.png",

            14000: "14000_jacket.png",
            14001: "14001_pants.png",
            14002: "14002_boots.png",

            15000: "15000_regalia.png",
            15001: "15001_vest.png",

            16000: "16000_skeleton.png",

            17000: "17000_venetian_red_cap.png",
            17001: "17001_headless.png",
            17002: "17002_venetian_green_cap.png",
            17003: "17003_galaxy_sneakers.png",
            17004: "17004_ronin_hat.png",
            17005: "17005_ronin_torso.png",
            17006: "17006_ronin_legs.png",
            17007: "17007_marcel_mask.png",
            17008: "17008_cowboy_hat.png",
            // 17009: "17009_wizard_hat.png",

            // 18000: "18000.png",
            // 18001: "18001.png",
            // 18002: "18002.png",
            // 18003: "18003.png",
            // 18004: "18004.png",
            // 18005: "18005.png",
            // 18006: "18006.png",
            // 18007: "18007.png",
            // 18008: "18008.png",
            // // 18009: "18009.png",

            // 19000: "19000.png",
            // 19001: "19001.png",
            // 19002: "19002.png",
            // 19003: "19003.png",
            // 19004: "19004.png",
            // //19005: "19005.png",
            // 19006: "19006.png",
            // 19007: "19007.png",
            // 19008: "19008.png",
            // 19009: "19009.png",

            // 20000: "20000.png",
            // 20001: "20001.png",
            // 20002: "20002.png",
            // 20003: "20003.png",
            // 20004: "20004.png",
            // 20005: "20005.png",
            // 20006: "20006.png",
            // 20007: "20007.png",
            // 20008: "20008.png",

            // 21000: "20000.png",
            // 21001: "20001.png",
            // 21002: "20002.png",
            // 21003: "20003.png",
            // 21004: "20004.png",
            // 21005: "20005.png",
            // 21006: "20006.png",
            // 21007: "20007.png",
            // 21008: "20008.png",
        };

        this.dateOptions = {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour12: false,
            hour: '2-digit',
            minute: '2-digit'
        };

        this.newWeb3 = new NewWeb3(this, this.onWeb3Loaded);
        // let defaultAccount;
        // if (this.newWeb3)
        // {
        //     defaultAccount = this.newWeb3.account;
        // }

        this.state = {
            account: null,
            isOwner: false,
            claimNum: 0,
            sig: "",
            wearableId: 0,
            wearName: "",
            wearLabel: "",
            dropdownOpen: false,
            currentAlert: "",
            alertOpen: false,
            errorOpen: false,
            successOpen: false,
            alertText: "",
            errorText: "",
            successText: "",
            tx: "",
            data:[],
            selectedClaim: null,
            isLoading: true
        };
        
        this.claimItem = this.claimItem.bind(this);
        this.selectClaim = this.selectClaim.bind(this);

        this.dismissAlert = this.dismissAlert.bind(this);
        this.showAlert = this.showAlert.bind(this);
    }

    onWeb3Loaded(self, acct, acctIsOwner)
    {
        console.log("Web3 loaded! account=" + acct); 
        if (self != null)
        {
            self.setState({
                account: acct,
                isOwner: acctIsOwner
            });
        }
    }

    async componentDidMount() {
        
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {

        // only continue if it's a new account
        //console.log("Updated. account=" + this.state.account + "; prev=" + prevState.account + ", isOwner=" + this.state.isOwner);
        if (this.state.account === prevState.account) return;

        try {

            // get web3 provider
            if (this.newWeb3 && this.newWeb3.web3)
            {
                //console.log("cd pre-check, account=" + this.state.account);
                if (!this.state.account) return;

                this.newWeb3.doLogin(this.onLoggedIn, this);
            }
 
        } catch (err) {
            console.error(err)
        }
    }
    
    onLoggedIn(self, webToken)
    {
        console.log("Logged in!");

        let claimData = [];
        try {
            // get web3 provider
            if (webToken)
            {
                //console.log("webToken exists: " + JSON.stringify(webToken));

                let defaultOptions = {
                    method:'POST',
                    mode: 'cors',
                    crossDomain: true,
                    headers:new Headers({ // config.origin
                        'Access-Control-Allow-Origin': "*",
                        "Ocp-Apim-Subscription-Key": "9cf168f463834a9380f2fbd98228d7cb", //"743ff2d6765e4235b67ef0a92a75fdfc",
                        'Content-Type': 'application/json; charset=utf-8',
                        //'x-access-token' : webToken.token 
                    }),
                    body: JSON.stringify({
                        addr: self.state.account, all: true
                    })
                };

                var callStr = config.wzApi + 'claims/open'; 
                //var callStr = config.wzApi + 'claims/open?code=' + config.openCode; 
                //console.log("calling: " + callStr);
                //console.log("options: " + JSON.stringify(defaultOptions));

                fetch(callStr, defaultOptions)
                .then((response) => { 

                    response.json().then(async json => {
                        //console.log("claims json = " + JSON.stringify(json));
                        let newObj;
                        if (json["ErrorNum"] || !Array.isArray(json))
                        {
                            console.log("Not an array!");
                            console.log("claims json = " + JSON.stringify(json));
                            self.showAlert("No wearable claims found for this address.");
                        }
                        else
                        {
                            for (const claim of json) {
    
                                newObj = await self.extractData(claim, self);
    
                                //console.log("newObj = ", newObj);
                                claimData.push(newObj); 
                            }
                            console.log("claims.length = " + JSON.stringify(claimData.length));
    
                            self.setState({
                                data: claimData,
                                count: claimData.length,
                                isLoading: false
                            }); 
                        }


                    }).catch((e) => alert("1 Failed to retrieve claim list: " + e));
                })
                .catch(() => alert("Error: 2 Failed to retrieve claim list."));
            }
 
        } catch (err) {
            console.error(err);
        }
    }

    async extractData(obj, self) {
        var dataObj = {};
        
        dataObj.claimNum = obj.ii;
        dataObj.wi = obj.wi;
        dataObj.addr = obj.addr;
        dataObj.sig = obj.sig;
        //let options = { dateStyle: "short", timeStyle: "short" }; // , hour: '2-digit', minute: '2-digit' 
        let dt = new Date(obj.time * 1000);
        dataObj.time = dt.toLocaleString([], this.dateOptions);

        dataObj.itemName = this.wearableNames[obj.wi];
        dataObj.iconUrl = (obj.wi >= 18000) ? obj.wi.toString() + ".png" : this.iconNames[obj.wi];

        let claimStr = "Mint Now";
        //console.log(this.newWeb3.chainId);

        if (this.newWeb3.chainId === "0x1")
        {
            // eth mainnet
            if (dataObj.wi >= 3000)
            {
                claimStr = "matic";
            }
            else
            {
                let claimed = await self.newWeb3.wearClaimerContract.methods.claimed(dataObj.claimNum).call();
                if (claimed) claimStr = "claimed";
            }
        }
        else if (this.newWeb3.chainId === "0x89")
        {
            // matic mainnet
            if (dataObj.wi < 3000)
            {
                claimStr = "eth main";
            }
            else
            {
                let claimed = await self.newWeb3.wearClaimerV2Contract.methods.claimed(dataObj.claimNum).call();
                //console.log("#" + dataObj.claimNum + ": claimed=" + claimed);
                if (claimed) claimStr = "claimed";
            }
        }
        else
        {
            // unsupported network
            claimStr = "change network";
        }
        dataObj.status = claimStr;

        return dataObj;
    }

    selectClaim(claimObj)
    {
        this.setState({
            selectedClaim: claimObj
        });
        this.claimItem(claimObj, this);
    }

    updateClaimStatus(claimNum, newVal, self)
    {
        //console.log("updateClaimStatus " + claimNum);  
        let claims = self.state.data;
        for (var i=0; i < claims.length; i++) 
        {
            if (claims[i].claimNum === claimNum)
            {
                claims[i].status = newVal;
                break;
            }
        }

        self.setState({
            data: claims
        });
    }

    claimItem(claimObj, self) 
    {
        //console.log("calling claimItem");        
        //console.log("selected: " + JSON.stringify(claimObj));
        if (this.state.account === null || this.state.account === undefined || this.state.account === "0x0" ) {
            this.showError("Please login to MetaMask and refresh your web browser.");
            return;
        }

        this.dismissAll();

        let claimNum = claimObj.claimNum;

        try { 
            
            let wearId = claimObj.wi;
            // if (wearId == 9000)
            // {
            //     wearId = 9002;
            // }
            // else if (wearId == 9002)
            // {
            //     wearId == 9000;
            // }
            let collIdx = Math.floor(wearId / 1000);
            let wearIdx = wearId % 1000;
            let sig = claimObj.sig;
            let chainId = self.newWeb3.chainId;

            let claimContract = (chainId === "0x89") ? self.newWeb3.wearClaimerV2Contract : self.newWeb3.wearClaimerContract;

            // testing
            claimContract.methods.claim(collIdx, wearIdx, claimNum, sig).estimateGas({from:self.state.account}).then((gasAmount) => {

                console.log("gasAmount " + JSON.stringify(gasAmount));
                var totalFee = Number(gasAmount) * 2;
                console.log("totalFee " + JSON.stringify(totalFee));
                var gasPrice = self.newWeb3.utils.toWei("200", 'Gwei');
                console.log("gasPrice " + JSON.stringify(gasPrice));
                var priorityFee = self.newWeb3.utils.toWei("30", 'Gwei');
        
                var promise = new Promise((resolve, reject) => {

                    // , gas: totalFee, gasPrice: gasPrice
                    claimContract.methods.claim(collIdx, wearIdx, claimNum, sig).send({from:self.state.account, gas: totalFee, gasPrice: gasPrice, maxPriorityFeePerGas: priorityFee})
                    .on('transactionHash', function(hash){
                        //console.log("transactionHash " + JSON.stringify(hash));
                        self.showEtherscanLink("Transaction submitted", hash, chainId);
                        self.updateClaimStatus(claimNum, "waiting...", self);
                        //self.showAlert("Transaction was submitted. Waiting for confirmation...");
                    })
                    .on('confirmation', function(confirmationNumber, receipt){
                        console.log("confirmation " + JSON.stringify(confirmationNumber));
                        //self.showAlert("Transaction was confirmed. Waiting for receipt...");
                        self.showEtherscanLink("Transaction completed", self.state.tx, chainId);
                    })
                    .on('receipt', function(receipt){
                        //console.log("receipt " + JSON.stringify(receipt));
                        self.showEtherscanLink("Transaction received", self.state.tx, chainId);
                        resolve(receipt);
                    })
                    .on('error', function(error){
                        console.log("reject error " + JSON.stringify(error));
                        reject(error);
                    });
                });
                promise.then((result) => {
                    self.showSuccess("Success! Your wearable is on its way.");
                    self.updateClaimStatus(claimNum, "claimed", self);

                }).catch((reason) => {
                    let msg;
                    //console.log("type=" + typeof(reason));
                    if (typeof(reason) == "object")
                    {
                        if (reason["message"] != null)
                        {
                            msg = reason["message"];
                        }
                        else
                        {
                            let str = JSON.stringify(reason);
                            msg = str.substring(0,100) + "...";
                            // if (str.indexOf("reverted") >= 0)
                            // {
                            //     msg = "Transaction has been reverted by the EVM.";
                            // }
                            // else
                            // {
                            //     msg = str.substring(0,120) + "...";
                            // }
                        }
                    }
                    else
                    {
                        let errorStr = reason.toString(); // JSON.stringify(error);
                        console.log("|" + errorStr + "|");
                        let idx = errorStr.indexOf("{", 3);
                        console.log("idx=" + idx);
                        
                        if (idx >= 0)
                        {
                            msg = errorStr.substring(0, idx);
                        }
                        else
                        {
                            msg = errorStr;
                        }
                    }
                    self.showError('Transaction failed: '+ msg);
                    self.updateClaimStatus(claimNum, "Try Again", self);
                });

            });
        } catch (error) {
            this.showError("Transaction failed. Please try again later: " + error);
            self.updateClaimStatus(claimNum, "Try Again", self);
        }
    }

    showAlert(msg)
    {
        //console.log("showAlert(" + msg + ")")
        if (msg == null)
        {
            msg = this.state.alertText;
        }
        this.setState({
            // successOpen: false,
            // errorOpen:false, 
            alertOpen: true,
            alertText: msg,
            tx: "",
            txLink: ""
        });
    }

    showEtherscanLink(prefix, txId, chainId)
    {
        //console.log("showEtherscanLink(" + txId + "), chainId=" + chainId);
        let msg = prefix + ': ' // '{<a href="https://ropsten.etherscan.io/tx/' + txId + '" className="alert-link" target="_blank">' + txId + '</a>}';
        let linkTxt = (chainId === "0x89") ? 'https://polygonscan.com/tx/' + txId : 'https://etherscan.io/tx/' + txId;

        this.setState({
            // successOpen: false,
            // errorOpen:false, 
            alertOpen: true,
            alertText: msg,
            tx: txId,
            txLink: linkTxt
        });
    }

    showError(msg)
    {
        //console.log("showError(" + msg + ")")
        if (msg == null)
        {
            msg = this.state.errorText;
        }
        this.setState({
            // alertOpen: false,
            successOpen:false, 
            errorOpen: true,
            errorText: msg
        });
    }

    showSuccess(msg)
    {
        //console.log("showSuccess(" + msg + ")")
        if (msg == null)
        {
            msg = this.state.successText;
        }
        this.setState({
            // alertOpen: false,
            errorOpen:false, 
            successOpen: true,
            successText: msg
        });
    }

    dismissAlert(evt)
    {
        //let isOpen = this.state.alertOpen
        this.setState({
            currentAlert: "",
            alertOpen: false
        });
    }

    dismissError(evt)
    {
        //let isOpen = this.state.alertOpen
        this.setState({
            errorOpen: false
        });
    }

    dismissSuccess(evt)
    {
        //let isOpen = this.state.alertOpen
        this.setState({
            successOpen: false
        });
    }

    dismissAll(evt)
    {
        //let isOpen = this.state.alertOpen
        this.setState({
            alertOpen: false,
            errorOpen: false,
            successOpen: false,
            txId: ""
        });
    }

    render(){

        let txId = "";
		let url = "";
        let linkTag = "";
        if (this.state.tx !== "")
        {
            txId = this.state.tx;
            url = (this.state.txLink !== "") ? this.state.txLink : "http://etherscan.io/tx/" + txId;
            linkTag = <a href={url} className="alert-link" target="_blank" rel="noopener noreferrer">{txId}</a>;
        }

        return (
        <div className="fullpage bg-image" id="signVoucherPage">
            <Row>
                <Col md={{ size: 8, offset: 2 }}>
                <div className="container wow fadeIn">
                    <div className="text-center">
                        <h2>Wondermine Wearables</h2>
                        <p><span>Account: {this.state.account === undefined || this.state.account === null ? "...": this.state.account.toString().substring(0,10) + "..."}  {this.state.isOwner ? " (owner)": ""}</span></p>
                    </div>
                    {(this.state.account == null) ?
                        <div>Please connect your wallet, such as MetaMask or Dapper, and reload the page.</div>
                    :
                        <div>
                                                        <Row>
                                {/* <Alert color="primary" id="submitted_alert" isOpen={this.state.currentAlert == "submitted_alert"} toggle={this.dismissAlert} fade={true}>
                                    Transaction has been submitted. Waiting for confirmation...
                                </Alert> */}
                                <Alert color="primary" id="status_alert" isOpen={this.state.alertOpen} toggle={() => this.dismissAlert()} fade={true}>
                                    {this.state.alertText}{linkTag}
                                </Alert>
                                <Alert color="danger" id="error_alert" isOpen={this.state.errorOpen} toggle={() => this.dismissError()} fade={true}>
                                    {this.state.errorText}
                                </Alert>
                                <Alert color="success" id="success_alert" isOpen={this.state.successOpen} toggle={() => this.dismissSuccess()} fade={true}>
                                    {this.state.successText}
                                </Alert>
{/*                                 
                                <Toast>
                                    <ToastHeader icon={<Spinner size="sm" />}>
                                        Reactstrap
                                    </ToastHeader>
                                    <ToastBody>
                                        This is a toast with a custom icon — check it out!
                                    </ToastBody>
                                </Toast> */}
                            </Row>
                            <Row className="auction-card card-details-row" align="center" style={{ backgroundColor: '#dfdfdf', paddingBottom: '0.6rem', textAlign: "center" }}>
                                <Col sm="12">
                                    <div style={{ textAlign: 'center' }}>
                                        <h5>Mint Your Crafted Wearables</h5>
                                        <p>Here are the wearables crafted for your address in WonderMine.<br />If you didn't mint one in-game, you can do it now:</p>
                                        <ol>
                                        <li style={{ textAlign: 'left' }}>Click a Mint Now button.</li>
                                        <li style={{ textAlign: 'left' }}>Review the transaction.<b> If it shows an error, reject it!</b></li>
                                        <li style={{ textAlign: 'left' }}>If the transaction looks good, confirm it.</li>
                                        <li style={{ textAlign: 'left' }}>When the transaction completes, you've got a new wearable!</li>
                                        </ol>
                                        <p>NOTE: Newer wearables are minted on the Matic (Polygon) network. You might need to change to the Matic network in MetaMask or another wallet to enable the Mint Now buttons.</p>
                                        <ClaimList claimData={this.state.data} selectClaim={this.selectClaim}  />
                                     </div>
                                </Col>
                            </Row>
                        </div>
                    }
                    </div>
                    <div className="footer" id="footer">

                        <div className="container text-center">
                            <div className="row footer-social">
                                <div className="col-lg-12">
                                    <ul className="list-inline">
                                    <li className="list-inline-item">
                                        <a href="https://discord.gg/pRczkzh" target="_blank" rel="noopener noreferrer">
                                            <img src="img/discord-logo-white-128x128.fw.png" alt="" width="32"/>
                                        </a>
                                        </li>
                                    </ul>
                                </div>
                                <NavLink href="/terms">Terms of Use</NavLink>
                            </div>
                            
                            <p className="copyright">&copy; 2020 Decent Amusements, LLC. All rights reserved.</p>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
        );
    }
}

export default ClaimItemPage;
