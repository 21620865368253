import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import LandingPage from '../pages/LandingPage.jsx';
//import SignVoucherPage from '../pages/SignVoucherPage.jsx';
import TermsPage from '../pages/TermsPage.jsx';
import ClaimItemPage from '../pages/ClaimItemPage.jsx';

class Content extends Component {
  render() {
    return (
        <div>
            <Switch>
                <Route exact path='/' component={LandingPage}/>
                <Route path='/claimItem' component={ClaimItemPage} />
                <Route path='/terms' component={TermsPage} />
            </Switch>
        </div>
    );
  }
}

export default Content;
